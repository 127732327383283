import React from 'react';
import { StructuredTextDocument } from 'react-datocms';
import { graphql } from 'gatsby';
import { keyframes } from '@emotion/react';
import { motion, useDragControls, useMotionValue } from 'framer-motion';
import Container from '../../../primitives/grid/container';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import Background from '../../../primitives/backgrounds';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import { colors } from '../../../primitives/colors';
import range from '../../../../util/range';
import { StyledButtonAsLink } from '../../../primitives/styled-button';
import { getLink } from '../../../primitives/link';

interface OnboardingStepSetProps {
    title: string
    text: StructuredTextDocument
}
const OnboardingStepSet = ({ richTitle, steps, link }: OnboardingStepSetProps): JSX.Element => {
    const notificationAnimation = keyframes({
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    });

    const lineAnimation = keyframes({
        '100%': {
            transform: 'scaleX(1)',
            opacity: 0.09,
        },
    });
    const x = useMotionValue(0);
    const innerElRef = React.useRef();
    const [sliderIndex, setSliderIndex] = React.useState(1);

    React.useEffect(() => {
        const unsub = x.onChange((e) => {
            const clientW = innerElRef.current.clientWidth * 0.85;
            const pos = range(-e, 0, clientW, 1, steps.length);
            const rounded = Math.ceil(pos - 0.2);
            if (sliderIndex !== rounded) {
                setSliderIndex(rounded);
            }
        });
        return () => {
            unsub();
        };
    }, [steps.length, x, sliderIndex]);

    const controls = useDragControls();
    const [isDragging, setIsDragging] = React.useState(false);

    const startDrag = (event: React.MouseEvent): void => {
        setIsDragging(true);
        controls.start(event);
    };

    const endDrag = (): void => {
        setIsDragging(false);
    };

    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <div css={{
            position: 'relative',
            marginBottom: '120px',
            [breakpoints.tb]: {
                marginBottom: '70px'
            },
            [breakpoints.mb]: {
                marginBottom: '40px'
            }
        }}
        >
            <Background
                breakpoints={{
                    dt: {
                        gradient: 'purple',
                        clip: 'bottom',
                    },
                }}
                css={{
                    marginBottom: '0',
                    [breakpoints.tb]: {
                        marginBottom: '0'
                    },
                    [breakpoints.mb]: {
                        marginBottom: '0'
                    }
                }}
            >
                <div css={{
                    overflow: 'hidden',
                }}
                >
                    <Container css={{
                        paddingTop: '145px',
                        paddingBottom: '100px',
                        [breakpoints.mb]: {
                            paddingTop: '78px',
                            paddingBottom: '30px',
                        },
                    }}
                    >
                        <Typography
                            override
                            fontWeight="light"
                            color="shadesWhite"
                            css={{
                                textAlign: 'center',

                                paddingBottom: '70px',
                                [breakpoints.mb]: {

                                    paddingBottom: '44px',
                                },
                            }}
                            fontSize={{
                                dt: 4053,
                                mb: 2429,
                            }}
                        >
                            <CustomStructuredTextRenderer data={richTitle} />
                        </Typography>
                        <div
                            ref={ref}
                            css={{
                                position: 'relative',
                            }}
                        >
                            <div
                                css={{
                                    position: 'relative',
                                    width: '100%',
                                    pointerEvents: 'none',
                                    [breakpoints.mb]: {
                                        pointerEvents: 'all',
                                    },
                                }}
                                onPointerDown={startDrag}
                                onPointerUp={endDrag}
                                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                            >
                                <motion.div

                                    css={{
                                        [breakpoints.mb]: {
                                            minWidth: `${steps.length * 100}%`,
                                            width: '100%',
                                        },
                                    }}
                                    dragControls={controls}
                                    dragConstraints={ref}
                                    drag="x"
                                    style={{
                                        x,
                                    }}
                                >
                                    <ol
                                        ref={innerElRef}
                                        css={{
                                            display: 'flex',
                                            listStyleType: 'none',
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        {steps.map((item, idx) => (
                                            <li
                                                key={item.title}
                                                css={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    position: 'relative',
                                                    width: '100%',
                                                    '&:not(:last-of-type):after': {
                                                        content: '""',
                                                        display: 'block',
                                                        height: '1px',
                                                        backgroundColor: colors.primaryLightPurple,
                                                        width: '25%',
                                                        position: 'absolute',
                                                        right: '-12.5%',
                                                        top: '100px',
                                                        opacity: '0',
                                                        transformOrigin: 'bottom left',
                                                        transform: 'scaleX(0)',
                                                        animation: `${lineAnimation} 0.4s forwards`,
                                                        animationDelay: `${(idx + 1) * 600}ms`,
                                                    },
                                                }}
                                            >
                                                <div
                                                    css={{ margin: 'auto' }}
                                                >
                                                    <div css={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                    >
                                                        <div css={{
                                                            opacity: '0',
                                                            transform: 'translateY(10px)',
                                                            width: '62px',
                                                            height: '62px',
                                                            backgroundColor: colors.primaryPurple,
                                                            borderRadius: borderRadius.full,
                                                            color: colors.shadesWhite,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            right: '-16px',
                                                            top: '-14px',
                                                            animation: `${notificationAnimation} 0.3s forwards`,
                                                            animationDelay: `${(idx + 1) * 600}ms`,
                                                        }}
                                                        >
                                                            <Typography
                                                                fontSize={{
                                                                    dt: 2429,
                                                                }}
                                                            >
                                                                {idx + 1}
                                                            </Typography>
                                                        </div>
                                                        {/* <div css={{
                                                            position: 'relative',
                                                            '&::after': {
                                                                opacity: '0',
                                                                transform: 'translateY(10px)',
                                                                content: `"${idx + 1}"`,
                                                                position: 'absolute',
                                                                width: '44px',
                                                                height: '44px',
                                                                backgroundColor: colors.primaryPurple,
                                                                borderRadius: borderRadius.full,
                                                                color: colors.shadesWhite,
                                                                fontSize: '13px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                right: '-16px',
                                                                top: '-14px',
                                                                animation: `${notificationAnimation} 0.3s forwards`,
                                                                animationDelay: `${(idx + 1) * 600}ms`,
                                                            },
                                                        }}
                                                        >
                                                            <DatoAsset
                                                                css={{
                                                                    maxWidth: '118px',
                                                                    maxHeight: '180px',
                                                                    borderRadius: borderRadius.semiLarge,
                                                                    overflow: 'hidden',
                                                                    transform: 'translateZ(0)',
                                                                }}
                                                                asset={item.image}
                                                            />
                                                        </div> */}
                                                        <Typography
                                                            color="shadesWhite"
                                                            as="h4"
                                                            fontSize={{
                                                                dt: 1822,
                                                            }}
                                                            css={{
                                                                paddingTop: '30px',
                                                                maxWidth: '216px',
                                                            }}
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                        <Typography
                                                            color="shadesWhite"
                                                            as="p"
                                                            fontSize={{
                                                                dt: 1322,
                                                            }}
                                                            css={{
                                                                opacity: 0.5,
                                                                paddingTop: '10px',
                                                                maxWidth: '216px',
                                                            }}
                                                        >
                                                            {item.text}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ol>
                                </motion.div>
                            </div>
                            <motion.div
                                css={{
                                    display: 'none',
                                    [breakpoints.mb]: {
                                        display: 'flex',
                                        gap: '5px',
                                        marginTop: '36px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                }}
                                whileInView={{
                                    opacity: 1,
                                }}
                            >
                                {steps.map((_, innerIdx) => (
                                    <div
                                        css={{
                                            transition: '0.2s',
                                            height: '6px',
                                            width: '6px',
                                            borderRadius: borderRadius.full,
                                            backgroundColor: colors.shadesWhite,
                                        }}
                                        style={{
                                            opacity: sliderIndex === innerIdx + 1 ? 1 : 0.2,
                                        }}
                                    />
                                ))}
                            </motion.div>
                        </div>
                        <div css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '48px',
                            [breakpoints.mb]: {
                                paddingTop: '30px',
                            },
                        }}
                        >
                            {link && link.length > 0 && (
                                <StyledButtonAsLink to={getLink(link[0].link)}>
                                    {link[0].title}
                                </StyledButtonAsLink>
                            )}
                        </div>
                    </Container>
                </div>
            </Background>
        </div>
    );
};

export default OnboardingStepSet;

export const query = graphql`
    fragment OnboardingStepSetProps on DatoCmsOnboardingStepSet {
        richTitle {
            blocks
            value
            links
        }
        steps {
            title
            text
        }
        link {
            ...LinkWithTitleProps
        }
    }
`;
